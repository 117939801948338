import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const styles = {
    global: (props: any) => ({
        body: {
            color: mode('#FFFFFF', '#FFFFFF')(props),
            bg: mode('#A4493D', '#000000')(props),
        },
    }),
}

const theme = extendTheme({ config, styles })

export default theme
