import { Global } from '@emotion/react'
import React from 'react'

const Fonts = () => (
    <Global
        styles={`
      @font-face {
        font-family: 'Sofia Pro';
        font-style: bold;
        font-weight: 600;
        src: url('./fonts/SofiaPro-bold.otf') format('opentype');
      }
      @font-face {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 400;
        src: url('./fonts/SofiaPro-Medium.otf') format('opentype');
      }
       @font-face {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 300;
        src: url('./fonts/SofiaPro-Light.otf') format('opentype');
      }
     `}
    />
)

export default Fonts
