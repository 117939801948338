import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './theme'
import Fonts from './Fonts'

ReactDOM.render(
    <React.StrictMode>
        <Fonts />
        <ChakraProvider theme={theme} colorModeManager={undefined}>
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
