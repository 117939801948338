import React, { useLayoutEffect } from 'react'
import {
    Box,
    Container,
    Fade,
    Flex,
    HStack,
    Link,
    ScaleFade,
    Text,
    useColorMode,
} from '@chakra-ui/react'
import { motion } from "framer-motion";

import { ReactComponent as Logo } from "./assets/logo.svg";
import { ReactComponent as LogoText } from "./assets/logo_text.svg";

const MotionScaleFade = motion(ScaleFade);
const MotionFade = motion(Fade);

const getCurrentThemeColor = () => {
    const currentHour = new Date().getHours()
    const isDay = currentHour > 6 && currentHour < 22
    return isDay ? 'light' : 'dark'
}


function App() {
    const { toggleColorMode, setColorMode } = useColorMode()

    useLayoutEffect(() => {
        const colorMode = getCurrentThemeColor()
        setColorMode(colorMode)
    }, [])

    return (
        <Container
            maxW="100%"
            minH={['100%','100vh']}
            onClick={toggleColorMode}
        >
            <Box position='absolute' top="1rem">
                <Logo />
            </Box>
            <Flex
                justifyContent="space-between"
                flexDirection="column"
                height="100vh"
            >
                <Flex justifyContent="center" alignItems="center" height="100%">
                    <MotionScaleFade
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: 4 }}
                    >
                        <LogoText />
                        <Text
                            as="h1"
                            fontSize="1rem"
                            fontFamily="Sofia Pro"
                            fontWeight="400"
                            textAlign="center"
                            mt="1rem"
                            letterSpacing="0.125rem"
                        >
                            COMING SOON
                        </Text>
                    </MotionScaleFade>
                </Flex>
                <MotionFade
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 2, delay: 1 }}
                >
                    <HStack
                        fontSize="0.8rem"
                        mb="1rem"
                        fontFamily="Sofia Pro"
                        fontWeight="400"
                    >
                        <Link
                            href="mailto:info@utopia-living.com"
                            _hover={{ opacity: '0.5' }}
                        >
                            Contact
                        </Link>
                        <Link
                            href="https://apply.workable.com/utopia-life-worth-living/"
                            _hover={{ opacity: '0.5' }}
                        >
                            Jobs
                        </Link>
                    </HStack>
                </MotionFade>
            </Flex>
        </Container>
    )
}

export default App
